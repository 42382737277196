import {OmneticEnvirionments} from './types';

export const environment: OmneticEnvirionments = {
  AG_GRID_LICENSE_KEY:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-064541}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Carvago_s.r.o.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TEAS_DMS}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{TEAS_DMS}_need_to_be_licensed___{TEAS_DMS}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_October_2025}____[v3]_[01]_MTc1OTQ0NjAwMDAwMA==1ec1e667769ddc4498c050d23e6a9fed',
  API_URL: 'https://api.dev.omnetic.dev',
  APPSYNC_URL: 'https://sync.dev.omnetic.dev/graphql',
  METADA_URL: 'https://api.dev.omnetic.dev/esb/teas/api',
  DATADOG_APPLICATION_ID: 'f7f254d0-cf1d-4146-a23b-331f03c806dd',
  DATADOG_CLIENT_TOKEN: 'pub478d8c201cac6d0a4a9eaf0fc012c941',
  ENV_TYPE: 'dev',
  FLAGSMITH_ENV: 'ieS6Un5oXjzKMAgJJ4cbPi',
  FLAGSMITH_URL: 'https://flagsmith.eag.guru/api/v1/',
  PHRASE_ACCESS_TOKEN: '',
  PHRASE_PROJECT_ID: '226ede4efebf3ce1ab7de07bf10b213d',
  SENTRY_DSN: 'https://f28432e534ff4238852c63fe045e3f77@o354794.ingest.sentry.io/5201681',
  GTM_CONTAINER_ID: 'GTM-MB67ZT5',
  DIGITAL_CERTIFICATE_DOMAIN: 'digital-certificate.dev.omnetic.dev',
  ONLINE_OFFER_DOMAIN: 'online-offer.dev.omnetic.dev',
  GOOGLE_API_KEY: 'AIzaSyBihCGRjmRTKRDVd9MOmmyH_XxV165m8ok',
};
